'use strict';

angular.module('enervexSalesappApp').service('AccountTerritoriesService', function(Account, AccountLocation, AccountTerritory, AccountTerritoryRequest, AccountTerritoryRequestComment, State, County, $stateParams){
	this.getStates = function (){
		return State.query({limit: 300});
	}
	this.getCounties = function (){
		return County.query();
	}

	this.getAccount = function (){
		return Account.get({
			id: $stateParams.accountId
		});
	}

	this.getAccountLocation =function(){
		return AccountLocation.get({
			id: $stateParams.accountLocationId,
			accountId: $stateParams.accountId
		});
	}

	this.updateAccountLocation = function(accountLocation){
		return AccountLocation.update({
			id: $stateParams.accountLocationId,
			accountId: $stateParams.accountId
		}, accountLocation);
	}

	this.saveNewLocation = function(accountLocation){
		return AccountLocation.save({
			accountId: $stateParams.accountId
		}, accountLocation);
	}

	this.getTerritories = function(){
		return AccountTerritory.query({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
			limit: 10000
		});
	}

	this.getRequests = function (){
		return AccountTerritoryRequest.query({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId
		});
	}

	this.getRequest = function (){
		return AccountTerritoryRequest.get({
			id: $stateParams.accountTerritoryRequestId,
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId
		});
	}

	this.approveRequest = function (){
		return AccountTerritoryRequest.approve({
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
		});
	}

	this.denyRequest = function(){
		return AccountTerritoryRequest.deny({
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
		});
	}

	this.getComments = function (){
		return AccountTerritoryRequestComment.query({
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId
		});
	}

	this.makeComment = function(comment){
		return AccountTerritoryRequestComment.save({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
		},comment);
	}
	this.updateComment = function(comment){
		return AccountTerritoryRequestComment.update({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
			id: comment._id
		}, comment);
	}

	this.removeComment = function (comment){
		return AccountTerritoryRequestComment.remove({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId,
			accountTerritoryRequestId: $stateParams.accountTerritoryRequestId,
			id: comment._id
		});
	}

	this.newRequest = function (requestData){
		return AccountTerritoryRequest.save({
			accountId: $stateParams.accountId,
			accountLocationId: $stateParams.accountLocationId
		}, {
			counties: requestData.counties,
			removedCounties: requestData.removedCounties
		})
	}
});
